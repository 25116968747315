const getBlockContent = fieldName => `${fieldName}[]{
  ...,
  markDefs[]{
    ...,
    _type == "internalLink" => {
      "slug": @.reference->slug
    }
  }
}`

export const contentQuery = `*[_type=="content"][0] | {
  header,
  subHead,
  "heroImage": heroImage.asset -> url,
  buttonLink,
  callout ,
  journeySegments[]{ journeySegmentTitle, segmentDescription, "journeySegmentImage": journeySegmentImage.asset -> url, link },
  testimonials[]
}`

export const bookContentQuery = `*[_type=="bookContent"][0] | {
  header,
  subHead,
  "heroImage": heroImage.asset -> url,
  buttons,
  author{..., "image": image.asset-> url},
  testimonials[]
}`

export const pagesQuery = `*[_type=="page"] | {
  title,
  "slug": slug.current, 
  "bannerImage": bannerImage.asset -> url,
  "content": ${getBlockContent("content")}
}`

export const treatmentsQuery = `*[_type=="treatment"] | {
  treatmentTitle,
  "slug": slug.current,
  publishedAt,
  "bannerImage": bannerImage.asset -> url,
  overview,
  symptoms[] -> { title, description },
  "description": ${getBlockContent("description")},
  "treatment": ${getBlockContent("treatment")}
}`

export const symptomsQuery = `*[_type=="symptom"] | {
  title,
  "slug": slug.current,
  publishedAt,
  "bannerImage": bannerImage.asset -> url,
  "description": ${getBlockContent("description")},
  "content": ${getBlockContent("content")}
}`

export const categoriesQuery = `*[_type=="symptomCategory"] | order(order) {
  categoryName,
  followUp,
  followUpAnswers[]{answerText, answerReference->{..., "slug": slug.current}},
}`