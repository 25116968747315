import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './index.scss'

export function Header() {
  const [isActive, setIsActive] = useState(false)
  return (
    <React.Fragment>
    <div className="header">
      <nav className="desktop-links">
        <Link to="/" onClick={() => setIsActive(false)}>Home</Link>
        <Link to="/book" onClick={() => setIsActive(false)}>The Book</Link>
        <Link to="/treatment-finder" onClick={() => setIsActive(false)}>Treatment Finder</Link>
        <Link to="/about" onClick={() => setIsActive(false)}>About</Link>
        <a href="mailto:briandhhc@gmail.com?subject=Mail from Our Site">Contact</a>
      </nav>
      <svg className={`hamburger ${isActive ? "cross" : ''}`} onClick={() => setIsActive(!isActive)} viewBox="0 0 800 600">
        <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top"></path>
        <path d="M300,320 L540,320" id="middle"></path>
        <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) "></path>
      </svg>
      <Link to="/" className="header-logo">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300"><g className="cls-1"><g id="Layer_1" data-name="Layer 1"><g className="cls-2"><path className="cls-3" d="M104.11,145.45c0,35.9,31.06,85.43,65,85.43,35.34,0,65-49.53,65-85.43,0-83.58-65-144.86-65-144.86S104.11,61.87,104.11,145.45Z"/><g className="cls-4"><path className="cls-3" d="M111.19,222.84c22.07,16.16,66.48,19.37,81.77-1.48,15.91-21.72-1.18-62.25-23.24-78.41-51.35-37.63-118.28-25.28-118.28-25.28S59.83,185.21,111.19,222.84Z"/></g><g className="cls-4"><path className="cls-3" d="M171.49,164c-18.66,14.31-32,50.2-18.48,67.84,14.09,18.36,51.65,14,70.31-.28C266.74,198.2,272.68,140,272.68,140S214.92,130.64,171.49,164Z"/></g><g className="cls-5"><path className="cls-3" d="M105,269.24c22.43,7.07,59.48-2.58,66.17-23.78,7-22.07-18.14-50.36-40.56-57.43C78.41,171.58,27.32,200.1,27.32,200.1S52.8,252.77,105,269.24Z"/></g><g className="cls-6"><path className="cls-3" d="M144,288.74c20.63-9.6,36.62-39.34,27.49-57.22-9.5-18.61-43.57-20.9-62.47-11.24-44,22.48-58.8,73.2-58.8,73.2S99.21,309.59,144,288.74Z"/></g></g></g></g></svg>
      </Link>
      <a href="https://www.amazon.com/dp/B08FGG9PYF" className="btb-btn"><button className="button">Buy the Book</button></a>
    </div>
    <nav className={`mobile-nav ${isActive ? "isActive" : ''}`}>
      <Link to="/" onClick={() => setIsActive(false)}>Home</Link>
      <Link to="/book" onClick={() => setIsActive(false)}>The Book</Link>
      <Link to="/treatment-finder" onClick={() => setIsActive(false)}>Treatment Finder</Link>
      <Link to="/about" onClick={() => setIsActive(false)} >About</Link>
      <a href="mailto:briandhhc@gmail.com?subject=Mail from Our Site" onClick={() => setIsActive(false)}>Contact</a>
      <a href="https://www.amazon.com/dp/B08FGG9PYF" onClick={() => setIsActive(false)} className="btb-btn" ><button className="button">Buy the Book</button></a>
    </nav>
    </React.Fragment>
  );
}