import React from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe"

import './index.scss'

const SubscribeForm = ({ status, message, onValidated }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
    });

return (
  <div id='subscribeOuter'>
    <h2 className="line">Subscribe to the newsletter</h2>
  <div className='subscribe-container' id='subscribe'>
    <label className='has-text-dark'>Your Email</label>
      {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
      {status === "error" && (
        <div
          style={{ color: "red" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          style={{ color: "green" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <div className="field">
        <input
        className="input"
        ref={node => (email = node)}
        type="email"
        placeholder="you@gmail.com"
        />
        <button
        id='subscribeButton'
        className="button"
        onClick={submit}
        >
          Subscribe
        </button>

      </div>
    </div>
  </div>
  );
};

export function Subscribe() {
    const url = "https://holistichandbook.us17.list-manage.com/subscribe/post?u=e6576e05ec01dd9a4e1124c13&amp;id=3e24b1ea4d"
    return (
      <section id='subscribe-section' className='subscribe-section'>
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <SubscribeForm
              status={status}
              message={message}
              onValidated={formData => subscribe(formData)}
            />
          )}
        />
      </section>
    );
}