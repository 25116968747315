import React from 'react'
import { BodyContent } from 'components/Sanity';

import './index.scss'

export function ReviewCard({ review, name}) {
  return (
    <div className="review-card">
      <svg className="quotes" width="78" height="29" viewBox="0 0 78 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.4 14.1V27.9H0.400024V18.8C0.400024 15.3334 1.00002 12.2 2.20002 9.40004C3.40002 6.53338 5.43336 3.66671 8.30002 0.800049L13.4 4.80005C11.8 6.53338 10.5667 8.13338 9.70002 9.60005C8.83336 11.0667 8.26669 12.5667 8.00003 14.1H14.4ZM33.6 14.1V27.9H19.6V18.8C19.6 15.3334 20.2 12.2 21.4 9.40004C22.6 6.53338 24.6334 3.66671 27.5 0.800049L32.6 4.80005C31 6.53338 29.7667 8.13338 28.9 9.60005C28.0334 11.0667 27.4667 12.5667 27.2 14.1H33.6Z" fill="#2CCF94"/>
        <path d="M58.443 1.50005V10.6C58.443 14.1334 57.843 17.3334 56.643 20.2001C55.443 23.0001 53.4097 25.8 50.543 28.6L45.443 24.6C47.043 22.8667 48.2763 21.2667 49.143 19.8C50.0097 18.3334 50.5763 16.8334 50.843 15.3H44.443V1.50005H58.443ZM77.643 1.50005V10.6C77.643 14.1334 77.043 17.3334 75.843 20.2001C74.643 23.0001 72.6097 25.8 69.743 28.6L64.643 24.6C66.243 22.8667 67.4763 21.2667 68.343 19.8C69.2097 18.3334 69.7763 16.8334 70.043 15.3H63.643V1.50005H77.643Z" fill="#2CCF94"/>
      </svg>
      <BodyContent blocks={review}/>
      <strong>{name}</strong>
    </div>
  );
}