import React from 'react';

import { TreatmentFinderForm } from 'pages/TreatmentFinder/TreatmentFinderForm'

import './index.scss'

export function TreatmentFinder() {
  document.title = `Find Your Treatment`
  return (
    <div className="treatment-finder page-content section-offset">
      <h2 className="page-title">Find your Treatment</h2>
      <p>Figuring out what herbs and treatments are needed when you catch a cold or flu can be challenging. The questionnaire below is designed to help.  Answering just a few questions will provide you with an herbal treatment, along with other holistic measures that are tailored to your symptoms.  To start the questionnaire, just click the questionnaire button below.</p>
      <TreatmentFinderForm />
    </div>
  );
}

