import React, { useEffect, useState, useContext } from 'react';

import { PrimaryForm } from 'pages/TreatmentFinder/TreatmentFinderForm/PrimaryForm'
import { SecondaryForm } from 'pages/TreatmentFinder/TreatmentFinderForm/SecondaryForm'

import { SymptomsContext } from 'contexts/SymptomsContext'

import './index.scss'

export function TreatmentFinderForm() {
  // const [selectedCategoryName, setSelectedCategoryName] = useState();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isCategorySelected, setIsCategorySelected] = useState(false);
  const { categories, loaded } = useContext(SymptomsContext)
  useEffect(() => {
    if (selectedCategory){setIsCategorySelected(true)}
  }, [selectedCategory])

  return (
    <div className="treatment-finder-form">
      {loaded && !isCategorySelected ? 
        <PrimaryForm
        categories={categories}
        setSelectedCategory={setSelectedCategory}/> : 
        <SecondaryForm 
        selectedCategory={selectedCategory}/>
      }
    </div>
  );
}

