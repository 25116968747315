import React, { useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom'
import { SymptomsContext } from 'contexts/SymptomsContext';

import { BodyContent } from 'components/Sanity/index'

import "./index.scss"

export function SymptomPage() {
  // the slug of the URL
  const { symptoms, loaded } = useContext(SymptomsContext)
  let { slug: urlSlug } = useParams()
  let history = useHistory();

  const symptomSlugs = symptoms.map((symptom) =>{ return symptom.slug })

  const slugMatch = symptomSlugs.includes(urlSlug)
  
    const symptom = symptoms.find(({ slug }) => {
      return slug === urlSlug })

  //check to see if the slug exists in our project, otherwise send us home
  useEffect(() => {
    console.log(symptom)
    if (loaded){
      if (slugMatch) {
        (document.title = symptom.title)
      } else {
        history.push("/")
      }
    } 
  }, [loaded, history, slugMatch, symptom])
  

  return (
    <div className="treatment-page section-offset">
      {loaded && 
        <div>
          {symptom.bannerImage && <img className="treatment-bannerImage" src={symptom.bannerImage} alt="banner"/> }
          <h2 className="treatment-page-title page-content">{symptom.title}</h2>
          <div className="treatment-page-content">
            <div className="treatment-content">
              <BodyContent blocks={symptom.content}/>
            </div>
          </div> 
        </div>
      }
    </div>
  );
}