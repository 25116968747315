import React, { useState, useEffect } from 'react';
import { getData } from 'utils/sanity/'

// Queries
import { categoriesQuery, symptomsQuery } from "utils/sanity/sanity-queries"

const SymptomsContext = React.createContext({});

const SymptomsProvider = (props) => {
  // Set two loading states for without and with data
  const [loaded, setLoaded] = useState(false);
  // Set a project state
  const [categories, setCategories] = useState([]);
  const [symptoms, setSymptoms] = useState([]);

  // Get the data from Symptoms
  useEffect(() => {
    async function fetchData() { 
      try {
        const categoriesResponse = await getData(categoriesQuery)
        setCategories(categoriesResponse)
        const symptomsResponse = await getData(symptomsQuery)
        setSymptoms(symptomsResponse)
        setLoaded(true)
      }
      catch (err) {
        console.log('fetch failed', err);
        throw err
      }
    }
    fetchData()
  }, []);

  return (
    <SymptomsContext.Provider value={{ loaded, categories, symptoms }}>
      {props.children}
    </SymptomsContext.Provider>
  );
} 

export { SymptomsContext, SymptomsProvider };