import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { TreatmentsProvider } from 'contexts/TreatmentsContext'
import { ContentProvider } from 'contexts/ContentContext'
import { SymptomsProvider } from 'contexts/SymptomsContext'
import { ScrollToTop } from './utils/ScrollToTop'

import 'style/index.scss';

import { Header } from 'components/Header'
import { Footer } from 'components/Footer'

import { Home } from 'pages/Home';
import { TreatmentFinder } from 'pages/TreatmentFinder';
import { Book } from 'pages/Book';
import { Blank } from 'pages/Blank';
import { Treatments } from 'pages/Treatments';
import { TreatmentPage } from 'pages/TreatmentPage';
import { SymptomPage } from 'pages/SymptomPage';

import * as serviceWorker from './serviceWorker';

function App() {
  return(
    <TreatmentsProvider>
      <SymptomsProvider>
        <ContentProvider>
          <Router>
            <ScrollToTop />
              <Header />
              <Switch>
                <Route exact path="/treatment-finder" component={TreatmentFinder}/>
                <Route exact path="/book" component={Book}/>
                <Route exact path="/treatments" component={Treatments}/>
                <Route exact path="/symptoms/:slug" component={SymptomPage}/>
                <Route exact path="/treatments/:slug" component={TreatmentPage}/>
                <Route exact path="/:slug" component={Blank}/>
                <Route path="/" component={Home}/>
              </Switch>
            <Footer />
          </Router>
        </ContentProvider>
      </SymptomsProvider>
    </TreatmentsProvider>
  )
}

window.onload = () => {
  ReactDOM.render(
    <App />,
    document.getElementById('root')
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
