import React, { useState, useEffect } from 'react';
import { getData } from 'utils/sanity/'

// Queries
import { treatmentsQuery } from "utils/sanity/sanity-queries"

const TreatmentsContext = React.createContext({});

const TreatmentsProvider = (props) => {
  // Set two loading states for without and with data
  const [loaded, setLoaded] = useState(false);
  // Set a project state
  const [treatments, setTreatments] = useState([]);


  // Get the data from Treatments
  useEffect(() => {
    async function fetchData() { 
      try {
        const treatmentsResponse = await getData(treatmentsQuery)
        setTreatments(treatmentsResponse)
        setLoaded(true)
      }
      catch (err) {
        console.log('fetch failed', err);
        throw err
      }
    }
    fetchData()
  }, []);

  return (
    <TreatmentsContext.Provider value={{ loaded, treatments }}>
      {props.children}
    </TreatmentsContext.Provider>
  );
}

export { TreatmentsContext, TreatmentsProvider };