import React, { useContext } from 'react';

// Contexts
import { ContentContext } from 'contexts/ContentContext'

import './index.scss'



export function Hero() {
  const { content, contentLoaded } = useContext(ContentContext)
  return (
    <div className="hero" 
    // Get background image from Sanity
    style={{backgroundImage:`url(${content.heroImage})`}}
    >
      <div className="hero-content">
        <h1>{content.header}</h1>
        <p>{content.subHead}</p>
        {contentLoaded && <a href={content.buttonLink.href} className="button">{content.buttonLink.link}</a>}
      </div>
    </div>
  );
}

