import React, { useState, useEffect } from 'react'
import Slider from "react-slick"

import { useWindowSize } from 'react-use';

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './index.scss'

export function CardCarousel(props) {
  let { width } = useWindowSize();
  // If width is bigger than 400px and less than 1200px, decide whether one, two, or three cards should be shown
  const  [numberOfTiles, setNumberOfTiles] = useState(1)

  useEffect(()=> {
  if (width < 440) {
    setNumberOfTiles(1);
    } else if (440 <= width <= 1300) {
    setNumberOfTiles (Math.floor(width / 440));
    console.log(width)
    console.log(numberOfTiles)
  } else if (width > 1320) {
    setNumberOfTiles(3);
    console.log("YAY")
  } 
  }, [width])
  

  var settings = {
    arrows: width > 480,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: width < 1300 ? numberOfTiles : 3,
    slidesToScroll: numberOfTiles
  };

  return (
    <Slider className="card-carousel" {...settings}>
      {props.children}
    </Slider>
  );
}