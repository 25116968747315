import React from 'react';
import { Link } from 'react-router-dom';

import './index.scss'

export function SecondaryForm({selectedCategory}) {
  return(
    <div>
      <h2>{selectedCategory.followUp}</h2>
      <ol className="primary-list"> {selectedCategory && selectedCategory.followUpAnswers.map((answer)=> {
        console.log(answer.answerReference)
        let link = "/treatments"
        if(answer.answerReference.slug){link = `/symptoms/${answer.answerReference.slug}`}
        return(
          <Link to={link}><li className="primary-item">{answer.answerText}</li></Link>
        )
      }
      )
        }
      </ol>
    </div>
  )
}