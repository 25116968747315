import React from 'react';

import './index.scss'

export function PrimaryForm({categories, setSelectedCategory}) {
  return(
    <div>
      <h2>What is your primary symptom?</h2>
      <ol className="primary-list">
        {categories.map((category, i) => (
          <li onClick={() => setSelectedCategory(category)} className="primary-item" key={i}>{category.categoryName}</li>
        ))}
      </ol>
    </div>
  )
}