import React, { useContext } from 'react';

import { TreatmentCard } from 'components/TreatmentCard'

import { TreatmentsContext } from 'contexts/TreatmentsContext'

import loading from 'images/loading.svg'


import './index.scss'

export function Treatments() {
  const { treatments, loaded } = useContext(TreatmentsContext)

  return (
    loaded ? 
    <div className="treatments section-offset">
      <div className="page-content">
        <h2 className="page-title">All Treatments</h2>
        <p>Figuring out what herbs and treatments are needed when you catch a cold or flu can be challenging.  The questionnaire below is designed to help.  Answering just a few questions will provide you with an herbal treatment, along with other holistic measures that are tailored to your symptoms.  To start the questionnaire, just click the questionnaire button below.</p>
      </div>
      <div className="treatment-grid">
        {
          treatments.map(({slug, bannerImage, treatmentTitle}) => (
            <TreatmentCard slug={slug} img={bannerImage} title={treatmentTitle}  />
          )
        )}
      </div>
    </div> 
    :
    <div className="loader-container">
    <img className="loader" src={loading} alt="loader"/>
    <p>loading...</p>
  </div>
  );
}

