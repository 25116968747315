import React, { useContext } from 'react';

import { Hero } from "pages/Home/Hero"
import { Subscribe } from "pages/Home/Subscribe"
import { CardCarousel } from 'components/CardCarousel'
import { ReviewCard } from 'components/ReviewCard'

// Contexts
import { ContentContext } from 'contexts/ContentContext'

import './index.scss'

export function Home() {
  const { content, contentLoaded } = useContext(ContentContext)
  console.log(content)

  document.title = `The Holistic Handbook`

  return (
    contentLoaded && <div className="home section-offset">
      <Hero />
      {/* treatment section */}
      <section className="treatment-section colored-section">
        <div className="section-content">
          <h2>{content.callout.calloutTitle}</h2>
          <p>{content.callout.calloutContent}</p>
          <a href={content.callout.calloutButtonLink.href} className="button">{content.callout.calloutButtonLink.link}</a>
        </div>  
      </section>
      {/* journey section */}
      <section className="journey-section">
      <div className="page-content">
        <h2 className="section-title">Begin Your Healing Journey</h2>
        {content.journeySegments.map((segment, i) => 
        <div className="journey-segment" key={i}>
          <img src={segment.journeySegmentImage} alt=""/>
          <div className="journey-segment-content">
            <h2>{segment.journeySegmentTitle}</h2>
            <p>{segment.segmentDescription}</p>
            <a href={segment.link.href}>{`${segment.link.journeySegmentLink} >`}</a>
          </div>
        </div>
        )}
      </div>
      </section>
      <section className="testimonials-section">
        <h2 className="section-title">Testimonials</h2>
        <CardCarousel>
          {content.testimonials.map((testimonial, i) => 
            <ReviewCard review={testimonial.review} name={testimonial.name} key={i} />
          )}
        </CardCarousel>
      </section>
      {/* subscribe section */}
      <Subscribe />
    </div>
  );
}

