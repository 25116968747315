import React, { useContext } from 'react';

import { CardCarousel } from 'components/CardCarousel'
import { ReviewCard } from 'components/ReviewCard'

// Contexts
import { ContentContext } from 'contexts/ContentContext'

// Images
import { amznbdge } from 'images/quotes.svg';

// Style
import './index.scss'

export function Book() {
  document.title = `The Holistic Handbook`
  const { bookContent, contentLoaded } = useContext(ContentContext)
  console.log(bookContent)
  return (
    <div className="book section-offset">
      <div className="book-hero">
        <div className="book-hero-content hero-header">
          <h1>{bookContent.header}</h1>
          <p>{bookContent.subHead}</p>
          {contentLoaded && bookContent.buttons.map((button, i) => (
            <a href={button.href} className='button' key={i} target={button.blank ? '_blank' : '_self'}>{button.link}</a>
          ))
        }
        </div>
        <img className="book-hero-image" src={bookContent.heroImage} alt="book-hero" />
      </div>
      {contentLoaded && <section className="about-author colored-section">
        <img className="author-image" src={bookContent.author.image} alt="author" />
        <div className="about-author-content">
          <h2>About the author</h2>
          <p>{bookContent.author.about}</p>
        </div>
      </section>}
      <section className="testimonials-section">
        <h2 className="section-title">Testimonials</h2>
        <CardCarousel>
          {contentLoaded && bookContent.testimonials && bookContent.testimonials.map((testimonial, i) => 
            <ReviewCard review={testimonial.review} name={testimonial.name} key={i} />
          )}
        </CardCarousel>
      </section>
    </div>
  );
}

