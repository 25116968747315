import React from 'react'
import { Link } from 'react-router-dom';

import './index.scss'

export function TreatmentCard({slug, img, title}) {
  return (
    <Link to={`/treatments/${slug}`}>
    <div className="treatment-card">
      <img src={img} alt=""/>
      <div className="treatment-card-content">
        <h2>{title}</h2>
      </div>
      </div>
  </Link>
  );
} 