import React, { useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom'
import { TreatmentsContext } from 'contexts/TreatmentsContext';

import { BodyContent } from 'components/Sanity/index'

import "./index.scss"

export function TreatmentPage() {
  // the slug of the URL
  const { treatments, loaded } = useContext(TreatmentsContext)
  let { slug: urlSlug } = useParams()
  let history = useHistory();

  const treatmentSlugs = treatments.map((treatment) =>{ return treatment.slug })

  const slugMatch = treatmentSlugs.includes(urlSlug)

  const treatment = treatments.find(({ slug }) => {
    return slug === urlSlug })

  //check to see if the slug exists in our project, otherwise send us home
  useEffect(() => {
    if (loaded){
      if (slugMatch) {
        (document.title = treatment.treatmentTitle)
      } else {
        history.push("/")
      }
    } 
  }, [loaded, history, slugMatch, treatment])

  treatment && console.log(treatment.treatment)

  return (
    loaded && 
    <div className="treatment-page section-offset">
      <div>
        <img className="treatment-bannerImage" src={treatment.bannerImage} alt="banner"/>
        <h2 className="treatment-page-title page-content">{treatment.treatmentTitle}</h2>
        <div className="treatment-page-content">
          <h3 className="treatment-section-label">Symptoms</h3>
          <div className="treatment-content"> 
            <p>You are here because you have one or more of the following symptoms:</p>
            {treatment.symptoms.map((symptom, key) =>
              <p key={key}>- {symptom.title}</p>
            )}
          </div>
          {treatment.description && 
            <React.Fragment>
              <h3 className="treatment-section-label">Description</h3>
              <div className="treatment-content">
                <BodyContent blocks={treatment.description}/>
              </div>
            </React.Fragment>
          }
          {treatment.treatment && 
          <React.Fragment>
            <h3 className="treatment-section-label">Treatment</h3>
            <div className="treatment-content">
              <BodyContent blocks={treatment.treatment}/>
            </div>
          </React.Fragment>
          }
        </div> 
      </div>
    </div>
  );
}